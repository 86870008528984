import React from 'react'
import PostListHorizontal from '../PostListHorizontal'
import ProductInfoCard from '../ProductInfoCard'
import Adsense from '../Adsense'
import './Sidebar.scss'

class Sidebar extends React.Component {
  render () {
    const location = this.props.location
    const posts = this.props.posts.filter(
      ({ node }) => node.fields.slug !== location.pathname
    )
    return (
      <aside className="page-aside">
        <div className="widget-ad">
          <Adsense type="sidebar-top" />
        </div>
        <div className="widget">
          <ProductInfoCard siteMeta={this.props.siteMeta} />
        </div>
        <div className="widget">
          <PostListHorizontal posts={posts} siteMeta={this.props.siteMeta} />
        </div>
        <div className="widget-ad">
          <Adsense type="sidebar-bottom" />
        </div>
        <div className="widget-ad">
          <Adsense type="sidebar-middle" />
        </div>
      </aside>
    )
  }
}

export default Sidebar
